import React, { Component } from 'react';
import Header from './components/header/Header';

import './app.sass'
import Content from './components/content/Content';

class App extends Component {
  render() {
    return (
      <div className="container">
        <Header />
        <Content />
      </div>
    );
  }
}

export default App;
