import * as React from "react";

import "./register.sass";
import "../form.sass";

interface IState {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirthDay: string;
  dateOfBirthMonth: string;
  dateOfBirthYear: string;
  streetName: string;
  streetNumber: string;
  postalCodeNumbers: string;
  postalCodeLetters: string;
  city: string;
  studentNumber: string;
  mail: string;
  password: string;
  passwordConfirm: string
  showExternal: boolean;
}
interface IProps {}

export default class Register extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirthDay: "",
      dateOfBirthMonth: "",
      dateOfBirthYear: "",
      streetName: "",
      streetNumber: "",
      postalCodeNumbers: "",
      postalCodeLetters: "",
      city: "",
      studentNumber: "",
      mail: "",
      password: "",
      passwordConfirm: "", 
      showExternal: false
    };
  }

  public render() {
    
    const date: string = this.state.dateOfBirthYear + "-" + this.state.dateOfBirthMonth + "-" + this.state.dateOfBirthDay;

    return (
      <form className="login-container" method="post">
        <h3>Personal information</h3>

        <h4>Name</h4>
        <div className="box">
          <input
            className={this.state.firstName !== "" ? "text-box-medium valid" : "text-box-medium not-valid"}
            type="text"
            name="firstName"
            placeholder="First name"
            value={this.state.firstName}
            onChange={e => this.setState({firstName: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className="text-box-medium"
            name="middleName"
            placeholder="Middle name"
            value={this.state.middleName}
            onChange={e => this.setState({middleName: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.lastName !== "" ? "text-box-medium valid" : "text-box-medium not-valid"}
            name="lastName"
            placeholder="Last name"
            value={this.state.lastName}
            onChange={e => this.setState({lastName: e.target.value})}
            autoComplete="off"
          />
        </div>

        <h4>Date of birth</h4>

        <div className="box">
          <input
            type="text"
            className={this.state.dateOfBirthDay !== "" ? "text-box-small valid" : "text-box-small not-valid"}
            name="dateOfBirthDay"
            placeholder="Day"
            value={this.state.dateOfBirthDay}
            onChange={e => this.setState({dateOfBirthDay: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.dateOfBirthMonth !== "" ? "text-box-small valid" : "text-box-small not-valid"}
            name="dateOfBirthMonth"
            placeholder="Month"
            value={this.state.dateOfBirthMonth}
            onChange={e => this.setState({dateOfBirthMonth: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.dateOfBirthYear !== "" ? "text-box-small valid" : "text-box-small not-valid"}
            name="dateOfBirthYear"
            placeholder="Year"
            value={this.state.dateOfBirthYear}
            onChange={e => this.setState({dateOfBirthYear: e.target.value})}
            autoComplete="off"
          />
        </div>

        <h4>Student nummer</h4>

        <div>
          <input
            type="text"
            className="text-box-big"
            name="studentNumber"
            placeholder="Student number"
            value={this.state.studentNumber}
            onChange={e => this.setState({studentNumber: e.target.value})}
            autoComplete="off"
          />
        </div>

        <h4>Address information</h4>

        <div className="box">
          <input
            type="text"
            className={this.state.streetName !== "" ? "text-box-medium valid" : "text-box-medium not-valid"}
            name="streetName"
            placeholder="Street name"
            value={this.state.streetName}
            onChange={e => this.setState({streetName: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.streetNumber !== "" ? "text-box-small valid" : "text-box-small not-valid"}
            name="streetNumber"
            placeholder="Streetnr"
            value={this.state.streetNumber}
            onChange={e => this.setState({streetNumber: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.city !== "" ? "text-box-medium valid" : "text-box-medium not-valid"}
            name="city"
            placeholder="City"
            value={this.state.city}
            onChange={e => this.setState({city: e.target.value})}
            autoComplete="off"
          />
        </div>

        <div className="box">
          <input
            type="text"
            className={this.state.postalCodeNumbers !== "" ? "text-box-medium valid" : "text-box-medium not-valid"}
            name="postalCodeNumbers"
            placeholder="Postalcode numbers"
            value={this.state.postalCodeNumbers}
            onChange={e => this.setState({postalCodeNumbers: e.target.value})}
            autoComplete="off"
          />

          <input
            type="text"
            className={this.state.postalCodeLetters !== "" ? "text-box-small valid" : "text-box-small not-valid"}
            name="postalCodeLetters"
            placeholder="Postal code letters"
            value={this.state.postalCodeLetters}
            onChange={e => this.setState({postalCodeLetters: e.target.value})}
            autoComplete="off"
          />
        </div>

        <div className="box">
          
        </div>

        <h4>Login information</h4>

        <div>
          <input
            type="text"
            className={this.state.mail !== "" ? "text-box-big valid" : "text-box-big not-valid"}
            name="mail"
            placeholder="Mail"
            value={this.state.mail}
            onChange={e => this.setState({mail: e.target.value})}
            autoComplete="off"
          />
        </div>

        <div>
          <input
            type="password"
            className={this.state.password !== "" ? "text-box-big valid" : "text-box-big not-valid"}
            name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={e => this.setState({password: e.target.value})}
            autoComplete="off"
          />
        </div>

        <div>
          <input
            type="password"
            className={this.state.passwordConfirm !== "" ? "text-box-big valid" : "text-box-big not-valid"}
            name="confirmPassword"
            placeholder="Confirm password"
            value={this.state.passwordConfirm}
            onChange={e => this.setState({passwordConfirm: e.target.value})}
            autoComplete="off"
          />
        </div>
        <div className="button">Registreren</div>
      </form>
    );
  }
}
