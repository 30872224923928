import * as React from 'react'

import './header.sass'

interface IState{}

interface IProps{}

export default class Header extends React.Component<IProps, IState> {
    
    public render(){
        return (
            <div className="header-container">
                <div className="content">
                    <div className="block">
                        <a className="text-left" href="/login">Login</a>
                        <a className="text-left" href="/register">Register</a>
                    </div>
                    <div className="block">
                        <a className="text-right" href="/">CSAR</a>
                    </div>
                </div>
            </div>

        );
    }
}