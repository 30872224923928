import * as React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom"

import './content.sass'
import Login from './login/Login';
import Register from './register/Register';
import Home from './home/Home';

export default class Content extends React.Component<{}, {}>{
    public render(){
        return(
            <BrowserRouter>
                <div className="content-container">
                    <div className="content">
                        <Switch>
                            <Route path="/" component={Home} exact />
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route component={Content} exact/>
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}