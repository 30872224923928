import * as React from "react";
import "./login.sass";
import "../form.sass"

interface IState {
  username: string
  password: string
}

interface IProps {}

export default class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  public render() {
    return (
      <form className="login-container" method="post">
        <div>
          <input
            type="text"
            className="text-box-big"
            name="username"
            placeholder="Username"
            autoComplete="off"
            onChange={value => this.changeUsername(value.target.value)}
          />
        </div>

        <div>
          <input
            type="password"
            className="text-box-big"
            name="password"
            placeholder="Password"
            onChange={value => this.changePassword(value.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="button" onClick={() => this.submit()}>
          Login
        </div>
      </form>
    );
  }

  private changeUsername(username: string) {
    this.setState({ username: username });
  }

  private changePassword(password: string) {
    this.setState({ password: password });
  }

  private submit() {
    if (this.state.username !== "" && this.state.password !== "") {
      console.log("heii");
    } else {
        console.log("Nope")
    }
  }
}
